import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../API";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "desc"
          }&items_per_page=${params?.items_per_page || "10"}&${getParams(
            "search"
          )}&page=${params?.current_page || ""}&${getParams("service_type_id")}${getParams("service_category_id")}status=${params?.status}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "desc"
          }&${getParams("start_date")}&items_per_page=${
            params?.items_per_page || "10"
          }&${getParams("search")}&${getParams("social_media")}page=${
            params?.page || ""
          }&${getParams("service_type_id")}${getParams("service_category_id")}status=${params?.status}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const expertise = createApi({
  reducerPath: "expertiseApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["expertise"],
  endpoints: (builder) => ({
    getExpertiseData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/expertise/list`,
      }),
      providesTags: ["expertise"],
    }),
    expertiseTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user-field-save`,
      }),

      invalidatesTags: ["expertise"],
    }),
  }),
});
export const{useGetExpertiseDataQuery, useExpertiseTableHeadDataMutation}=expertise
