import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useFilterExpertise from "./useFilterExpertise";
import Select from "react-select";

const FilterExpertise = ({ refetch }) => {
  const { formik, getFieldError, handleCloseModal,basicData} = useFilterExpertise({
    refetch,
  });
  return (
    <div className="col">
       <div className="pro-mb-4">
        <label
          htmlFor="service_type_id"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Service Type
        </label>
        <Select
          id="service_type_id"
          placeholder={"Service Type"}
          className={`pro-input lg  ${
            getFieldError("service_type_id") && " error"
          }`}
          classNamePrefix="pro-input"
          name="service_type_id"
          options={basicData?.service_types_list ?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={basicData?.service_types_list?.find(
            (service) => service._id === formik?.values?.service_type_id
          )}
          onBlur={formik.handleBlur("service_type_id")}
          onChange={(value) => {
            formik.setFieldValue("service_type_id", value?._id ?? "");
            formik.setFieldValue("service_category_id", "");
          }}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("service_type_id") && (
          <span className="error-text">{getFieldError("service_type_id")}</span>
        )}
      </div>
      <div className="pro-mb-4">
        <label
          htmlFor="service_category_id"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Service Category
        </label>
        <Select
          id="service_category_id"
          placeholder={"Service Category"}
          className={`pro-input lg  ${
            getFieldError("service_category_id") && " error"
          }`}
          classNamePrefix="pro-input"
          name="service_category_id"
          options={
            basicData?.service_types_list?.find(
              (service) => service._id === formik?.values?.service_type_id
            )?.categories ?? []
          }
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={
            basicData?.service_types_list
              ?.find(
                (service) => service._id === formik?.values?.service_type_id
              )
              ?.categories?.find(
                (service) => service._id === formik?.values?.service_category_id
              ) ?? ""
          }
          onBlur={formik.handleBlur("service_category_id")}
          onChange={(value) =>
            formik.setFieldValue("service_category_id", value?._id ?? "")
          }
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("service_category_id") && (
          <span className="error-text">
            {getFieldError("service_category_id")}
          </span>
        )}
      </div>
      <div className="pro-mb-4">
        <label htmlFor="status" className="pro-font-sm pro-mb-1 pro-fw-medium">
         Status
        </label>
        <Select
          id="status"
          placeholder={"Status"}
          className={`pro-input lg  ${getFieldError("status") && " error"}`}
          classNamePrefix="pro-input"
          name="status"
          options={basicData?.service_type_status ?? []}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={ basicData?.service_type_status?.find(
            (status) => status.id === formik?.values?.status
          ) ?? ''}
          onBlur={formik.handleBlur("status")}
          onChange={(value) => formik.setFieldValue("status", value?.id ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("status") && (
          <span className="error-text">{getFieldError("status")}</span>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4  pro-mt-auto offcanvas-footer-sticky-btn`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik?.handleSubmit}
          type="submit"
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default FilterExpertise;
