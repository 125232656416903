import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddSubCategory from "./useAddSubCategory";

const AddSubCategory = ({ refetch }) => {
  const {
    formik,
    isEdit,
    getFieldError,
    handleCloseModal,
    handleStatusChange,
    basicData,
  } = useAddSubCategory({
    refetch,
  });
  return (
    <div className="col">
      <div className="pro-mb-4">
        <Input
          label={"Name"}
          type="text"
          id="name"
          name="name"
          className={`pro-input lg ${getFieldError("name") && " error"}`}
          {...formik.getFieldProps("name")}
          error={getFieldError("name")}
          errorMessage={getFieldError("name")}
        />
      </div>
      <div className="pro-mb-4">
        <label
          htmlFor="service_type_id"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Service Type
        </label>
        <Select
          id="service_type_id"
          placeholder={"Service Type"}
          className={`pro-input lg  ${
            getFieldError("service_type_id") && " error"
          }`}
          classNamePrefix="pro-input"
          name="service_type_id"
          options={basicData?.service_types_list ?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={basicData?.service_types_list?.find(
            (service) => service._id === formik?.values?.service_type_id
          )}
          onBlur={formik.handleBlur("service_type_id")}
          onChange={(value) => {
            formik.setFieldValue("service_type_id", value?._id ?? "");
            formik.setFieldValue("service_category_id", "");
          }}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("service_type_id") && (
          <span className="error-text">{getFieldError("service_type_id")}</span>
        )}
      </div>
      <div className="pro-mb-4">
        <label
          htmlFor="service_category_id"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Service Category
        </label>
        <Select
          id="service_category_id"
          placeholder={"Service Category"}
          className={`pro-input lg  ${
            getFieldError("service_category_id") && " error"
          }`}
          classNamePrefix="pro-input"
          name="service_category_id"
          options={
            basicData?.service_types_list?.find(
              (service) => service._id === formik?.values?.service_type_id
            )?.categories ?? []
          }
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={
            basicData?.service_types_list
              ?.find(
                (service) => service._id === formik?.values?.service_type_id
              )
              ?.categories?.find(
                (service) => service._id === formik?.values?.service_category_id
              ) ?? ""
          }
          onBlur={formik.handleBlur("service_category_id")}
          onChange={(value) =>
            formik.setFieldValue("service_category_id", value?._id ?? "")
          }
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("service_category_id") && (
          <span className="error-text">
            {getFieldError("service_category_id")}
          </span>
        )}
      </div>
      {isEdit && (
        <div className="pro-toggle">
          <div className="pro-toggle-box ">
            <input
              type="checkbox"
              role="switch"
              id="status"
              checked={formik?.values?.status}
              onChange={handleStatusChange}
            />
            <span></span>
          </div>
          <label htmlFor="status">Status</label>
        </div>
      )}
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default AddSubCategory;
