import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Category/categorySlice";
import { createCategory, updateCategory } from "../api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { getBasicData } from "../../../../Store/Slices/Global";

const useAddCategory = ({ refetch }) => {
  const dispatch = useDispatch();
  const {isEdit, itemData, itemId } = useSelector((state) => state.category);
  const { basicData } = useSelector((state) => state.global);
  const [status, setStatus] = useState(true);
  const setBasicData = async () => {
    localStorage?.setItem("basicData", "");
    dispatch(getBasicData());
  };
  const validationSchema = Yup.object({
    name: Yup.string()
    .required("*Name is required")
    .min(3, "Name must be at least 3 characters long")
    .matches(/^[^\s].*$/, "Name must not start with a space"),
    servicetype: Yup.object().required("*Service type is required")
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      servicetype:"",
    },
    validationSchema,
    onSubmit: (values) => {
      if (isEdit) {
        let data = {
          service_category_id: itemId,
          name: values.name,
          status:status?1:0,
          service_type_id:values?.servicetype?.id
          // _id: values?.servicetype?.id,
        };
        updateCategory(data).then((response) => {
          if (response?.status === 200) {
            toast.success("Added");
            setBasicData();
            refetch();
            handleCloseModal();
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } 
          else if (response?.success === false) {
            let errors = response?.message;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            })
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        let data = {
          name: values?.name,
          service_type_id: values?.servicetype?.id,
        };
        createCategory(data).then((response) => {
          if (response?.status === 200) {
            toast.success("Added");
            setBasicData();
            refetch();
            handleCloseModal();
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          }else if (response?.success === false) {
            let errors = response?.message;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            })
         
          }else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  useEffect(() => {
    if (isEdit) {
      Object.keys(itemData || {}).forEach((key) => {
        dispatch(
          updateConfig(
            (state) => (state.imagePreview = itemData?.image)
          )
        );
        if (key === "name") {
          formik.setFieldValue("name", itemData?.[key]);
        } else if (key === "service_type_id") {
          const service = basicData?.service_types.find(service => service.id === itemData?.service_type_id);
          formik.setFieldValue("servicetype", service);
          itemData?.status === 1 ? setStatus(true) : setStatus(false);
          // itemData?.[key] === 1 ? setStatus(true) : setStatus(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, itemData]);


  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.createModal = false;
        state.itemData = "";
        state.imagePreview = "";
      })
    );
  };
  const handleStatusChange = () => {
    setStatus(!status);
  };
  return {
    formik,
    isEdit,
    getFieldError,
    handleCloseModal,
    status,
    handleStatusChange,
    basicData
  };
};

export default useAddCategory;
