import { useSelector } from "react-redux";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";

const useConfigure = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Staff",
      label: "Staff",
      link: "/configure/staff",
      iconAsset: "DrawerIcon1",
      icon: (
       <span className="material-symbols-outlined x4">person_check</span>
      ),
      active: checkIfActiveRoute("/configure/staff"),
    },
    {
      title: "Role",
      label: "Role",
      link: "/configure/role",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">verified</span>
      ),
      active: checkIfActiveRoute("/configure/role"),
    },
    {
      title: "Service Type",
      label: "Service Type",
      link: "/configure/servicetype",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">deployed_code_account</span>
      ),
      active: checkIfActiveRoute("/configure/servicetype"),
    },
    {
      title: "Expertise",
      label: "Expertise",
      link: "/configure/expertise",
      iconAsset: "DrawerIcon1",
      icon: (
          <span className="material-symbols-outlined x4">military_tech</span>
        ),
      active: checkIfActiveRoute("/configure/expertise"),
      },
      {
        title: "Category",
        label: "Category",
        link: "/configure/category",
        iconAsset: "DrawerIcon1",
        icon: (
            <span className="material-symbols-outlined x4">category</span>
          ),
        active: checkIfActiveRoute("/configure/category"),
      },
      {
        title: "Sub Category",
        label: "Sub Category",
        link: "/configure/sub-category",
        iconAsset: "DrawerIcon1",
        icon: (
            <span className="material-symbols-outlined x4">dashboard</span>
          ),
        active: checkIfActiveRoute("/configure/sub-category"),
        },
      {
        title: "Tags",
        label: "Tags",
        link: "/configure/tags",
        iconAsset: "DrawerIcon1",
        icon: (
          <span className="material-symbols-outlined x4">sell</span>
        ),
        active: checkIfActiveRoute("/configure/tags"),
      },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.menu_permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );
    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.label);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.menu_permissions, location.pathname]);
  return {
    drawerMenu: drawerMenuPermission,
  };
};

export default useConfigure;
