import { useSelector } from "react-redux";
import { subcategory,useGetSubCategoryDataQuery,useSubcategoryTableHeadDataMutation } from "../../../Store/Queries/SubCategory";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/SubCategory/subcategorySlice";
import { updateStatus, viewSubCategory, deleteSubCategory } from "./api";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";

const useSubCategory = () => {
  const dispatch = useDispatch();
  const subCategoryState = useSelector((state) => state.subcategory);
  const { tableFields,showEditModal} = useSelector((state) => state.global);
  let actionOptions = [{ label: "Edit Columns", value: 0 }];
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: subCategoryList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetSubCategoryDataQuery({
    items_per_page: subCategoryState.currentPageSize,
    current_page: subCategoryState.currentPage,
    sort_by: subCategoryState.sortBy,
    sort_order: subCategoryState.sortOrder,
    search: subCategoryState.search,
    status:subCategoryState?.status,
    service_type_id:subCategoryState?.service_type_id,
    service_category_id:subCategoryState?.service_category_id,

  });
  const [updateSubCategoryFields] = useSubcategoryTableHeadDataMutation();
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = subCategoryList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [subCategoryList]);
  

  const handleChange = (data) => {
    let params = {
      sub_category_id: data?._id,
      status: data.status === 1 ? 0 : 1,
    };
    updateStatus(params);
    // refetch()
    dispatch(
      subcategory.util.updateQueryData(
        "getSubCategoryData",
        {
          items_per_page: subCategoryState.currentPageSize,
            current_page: subCategoryState.currentPage,
            sort_by: subCategoryState.sortBy,
            sort_order: subCategoryState.sortOrder,
            search: subCategoryState.search,
            status:subCategoryState?.status,
            service_type_id:subCategoryState?.service_type_id,
            service_category_id:subCategoryState?.service_category_id,
                },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === 1 ? 0 : 1,
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              data: newResult,
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (feild, data) => {
        const status = data[feild] === 1;
        return (
          <>
              <div className="pro-toggle">
                <div className="pro-toggle-box">
                  <input
                    type="checkbox"
                    role="switch"
                    id="status"
                    checked={status}
                    onChange={() => handleChange(data)}
                  />
                  <span></span>
                </div>
              </div>
          </>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => {
      state.currentPageSize= 10;
      state.currentPage= 1;
      state.sortBy = "";
      state.sortOrder = "";
      state.search = "";
      state.status = "";
      state.service_type_id = "";
      state.service_category_id="";
    }));
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (subCategoryState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = subCategoryState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = true;
        state.createModal = true;
        state.itemId = e?.[0];
      })
    );
    viewSubCategory(e?.[0]).then((response) => {
      if (response?.status === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
        state.createModal = true;
      })
    );
  };

  const handleCloseClick = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };
  const handleDeleteAction = () => {
    let id = subCategoryState?.itemId;
    if(subCategoryList?.data?.permissions?.can_delete){
        deleteSubCategory(id).then((response) => {
        if (response?.status === 200) {
          toast.success("Deleted");
          refetch();
          handleDeleteCloseModal();
          dispatch(updateConfig((state) => (state.clearSelection = true)));
          const clearSelection = setTimeout(() => {
            dispatch(updateConfig((state) => (state.clearSelection = false)));
            clearTimeout(clearSelection);
          }, 500);
        } else {
          toast.error("Something went wrong");
          handleDeleteCloseModal()
        }
      });
    }else{
      toast.error("Dont have an access to Delete");
    }
  };
  
  const handleDeleteCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = ''
        state.itemData=''
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = e?.[0];
      })
    );
    viewSubCategory(e?.[0]).then((response) => {
      if (response?.status === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleFilter = () => {
    dispatch(
      updateConfig((state) => {
        state.isFilter = false;
        state.createFilterModal = true;
      })
    );
  };
  const handleCloseFilter = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };
  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    subCategoryList,
    subCategoryState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
    handleDeleteModal,
    handleDeleteCloseModal,
    handleDeleteAction,
    handleFilter,
    handleCloseFilter,
    actionOptions,
    handleActionChange,
    updateSubCategoryFields,
    showEditModal
  };
  
};

export default useSubCategory;
