/* eslint-disable array-callback-return */
import React from "react";
import Style from "./permissions.module.scss";

export const Permissions = ({
  title,
  permissions,
  module,
  uniqueID,
  singleID,
  defaultCheckedList,
  handleMenu,
  handleSubMenu,
  handleSubmenuPermission,
}) => {

  return (
    <>
      <div className="col-12 pro-pb-5 pro-pt-1">
        <div className="row">
          <div className="col-2">
            <div className="pro-check-box pro-mt-4">
              <input
                type="checkbox"
                id={singleID}
                checked={defaultCheckedList?.some?.(
                  (elm) => elm?.module_id === singleID
                )}
                name="pro-check-box1"
                className="pro-check"
                onChange={(e) => handleMenu?.(e, singleID)}
              />
              <label
                htmlFor={singleID}
                className="pro-ttl pro-ms-3 h6 pro-mb-0"
              >
                {title}
              </label>
            </div>
            {/* <h6 className="pro-ttl h6 pro-mb-0">{title}</h6> */}
          </div>
          <div className="col-10">
            {permissions?.length > 0 && (
              <div className="row">
                {permissions.map((item, index) => {
                  return (
                    <div className="col-12 pro-py-4" key={index}>
                      <div className="row pro-w-100 gx-5 gy-4">
                        <div className={`col-auto ${Style.main_role}`}>
                          <div className="pro-check-box">
                            <input
                              type="checkbox"
                              id={item.id}
                              checked={defaultCheckedList?.some((nestedArray) =>
                                nestedArray.permissions?.some(
                                  (items) =>
                                    items?.id === item?.id ||
                                    items.sub_menu_permissions?.includes(
                                      item?.id
                                    )
                                )
                              )}
                              name="pro-check-box1"
                              className="pro-check"
                              // onChange={(e) => handleSubMenu?.({ e, module })}
                              onChange={(e) =>
                               { 
                                handleSubMenu?.({ e, module, ...item })}
                              }
                            />
                            <label
                              // htmlFor={submenu[uniqueID]}
                              className="pro-check-label"
                            >
                              {item?.name || ""}
                            </label>
                          </div>
                        </div>
                        {item?.sub_menu_permissions &&
                          item?.sub_menu_permissions.map(
                            (submenu, subIndex) => {
                              return (
                                <div className={`col-auto ${Style.main_role}`} key={subIndex}>
                                  <div className="pro-check-box">
                                    <input
                                      type="checkbox"
                                      id={submenu.id}
                                      checked={defaultCheckedList?.some(
                                        (nestedArray) =>
                                          nestedArray.permissions?.some(
                                            (item) =>
                                              item?.id === submenu?.id ||
                                              item.sub_menu_permissions?.includes(
                                                submenu?.id
                                              )
                                          )
                                      )}
                                      name="pro-check-box1"
                                      className="pro-check"
                                      onChange={(e) =>
                                        // handleChange?.({ e, module })
                                        handleSubmenuPermission?.({ e, module, ...submenu,...{"subId":item?.id} })
                                      }
                                    />
                                    <label
                                      htmlFor={submenu[uniqueID]}
                                      className="pro-check-label"
                                    >
                                      {submenu?.name || ""}
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
