import { useSelector } from "react-redux";
import { useMemo } from "react";
import Providers from "../Components/Users/Providers";
import Dashboard from "../Components/Dashboard";
import Seeker from "../Components/Users/Seeker";
import Verification from "../Components/Users/Verification";
import Posts from "../Components/Posts";
import Booking from "../Components/Booking";
import Payments from "../Components/Payments";
import FAQ from "../Components/CMS/FAQ";
import Staff from "../Components/Configure/Staff";
import Role from "../Components/Configure/Role";
import Expertise from "../Components/Configure/Expertise";
import Tags from "../Components/Configure/Tags";
import ServiceTypes from "../Components/Configure/ServiceTypes";
import Termsandconditions from "../Components/CMS/TermsandConditons";
import Privacypolicy from "../Components/CMS/PrivacyPolicy";
import SubCategory from "../Components/Configure/SubCategory";
import Category from "../Components/Configure/Category";
// components



const useRoutes = () => {
  const globalState = useSelector((state) => state.global);
  const dashBoardComponents = [
    {
      component: <Dashboard/>,
      permission: "Dashboard",
      path: "dashboard",
    },
  ];
  const usersComponents = [
    {
      component: <Providers/>,
      permission: "Providers",
      path: "users/providers",
    },
    {
      component: <Seeker/>,
      permission: "Seeker",
      path: "users/Seeker",
    },
    {
      component: <div><Verification/></div>,
      permission: "Verification",
      path: "users/verification",
    },
  ];
  const postsComponents = [
    {
      component: <div><Posts/></div>,
      permission: "Posts",
      path: "posts",
    },
  ];
  const bookingComponents = [
    {
      component: <div><Booking/></div>,
      permission: "Booking",
      path: "booking",
    },
  ];
  const paymentsComponents = [
    {
      component: <div><Payments/></div>,
      permission: "Payments",
      path: "payments",
    },
  ];
  const cmsComponents = [
    {
      component: <FAQ/>,
      permission: "FAQ",
      path: "CMS/FAQ",
    },
    {
        component:<div><Privacypolicy/></div>,
        permission: "Privacy Policy",
        path: "privacypolicy",
    },
    {
      component: <div><Termsandconditions/></div>,
      permission: "Terms And Conditions",
      path: "termsandconditions",
    },
  ];
  const configureComponents = [
    {
      component: <Staff/>,
      permission: "Staff",
      path: "configure/staff",
    },
    {
      component: <Role/>,
      permission: "Role",
      path: "configure/role",
    },
    {
      component: <Expertise/>,
      permission: "Expertise",
      path: "configure/expertise",
    },
    {
      component: <Category/>,
      permission: "Category",
      path: "configure/category",
    },
    {
      component: <SubCategory/>,    
      permission: "Sub Category",
      path: "configure/sub-category",
    },
    {
      component: <ServiceTypes/>,
      permission: "Service Categories",
      path: "configure/ServiceCategories",
    },
    {
      component: <Tags/>,
      permission: "Tags ",
      path: "configure/tags",
    }
  ];


  const drawerMenuPermission = useMemo(() => {
    let menus = globalState?.menu_permissions?.flatMap?.(
      (menu) => [
        menu?.menu_name,
        ...menu.permissions?.map((side_menu) => side_menu.sub_menu_name),
      ]
    );
    if (!menus) {
      menus = [];
    }
    menus = [...menus];
    return menus;
   
    //eslint-disable-next-line
  }, [globalState.menu_permissions, location.pathname]);

  function hasPermission(permission) {
    const user = {
      permissions: drawerMenuPermission,
    };
    return user.permissions?.includes(permission);
  }
  const allowedDashBoardComponents = dashBoardComponents.filter(
    ({ permission }) => hasPermission(permission)
  );
  const allowedUserComponents = usersComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedPostsComponents = postsComponents.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedBookingComponents = bookingComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedPaymentsComponents = paymentsComponents.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedCmsComponents = cmsComponents.filter(
    ({ permission }) => hasPermission(permission)
  );
  const allowedConfigureComponents = configureComponents.filter(
    ({ permission }) => hasPermission(permission)
  );

  return {
    allowedDashBoardComponents,
    allowedUserComponents,
    allowedPostsComponents,
    allowedBookingComponents,
    allowedPaymentsComponents,
    allowedCmsComponents,
    allowedConfigureComponents
  };
};

export default useRoutes;
