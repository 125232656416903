import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getMenuPermissions,
  updateConfig as updateGlobalConfig,
} from "../../../../Store/Slices/Global";
import {
  getAllPermissions,
  getPermission,
  updateConfig,
} from "../../../../Store/Slices/Role/roleSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AddRole, updateRole } from "../api";
import { role } from "../../../../Store/Queries/Role";
const useManageRole = () => {
  const [params] = useSearchParams();
  const roleparam = params.get("role");
  const update = params.get("update");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.role);
  const [status, setStatus] = useState(false);
  const [permissionModules, setPermissionModules] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line
    dispatch(
      updateConfig((state) => {
        state.rolePermission ={};
      })
    );
    setPermissionModules([])
    dispatch(
      updateGlobalConfig((state) => {
        state.menuDrawer = {
          show: true,
          backTo: "role",
          text: "Back to Roles",
        };
      })
    );
    if (state.allPermissionsStatus === "idle") {
      dispatch(getAllPermissions());
    }
    if (roleparam) {
      dispatch(getPermission({ id: roleparam }))
        .unwrap()
        .then((response) => {
          if (update === "true") {
            const menuPermissions = response?.data?.data?.modules ?? [];
            setPermissionModules(menuPermissions);
            formik?.setFieldValue("name", response?.data?.data?.name);
            formik?.setFieldValue("level", response?.data?.data?.level);
            setStatus(response?.data?.data?.status);
          }
        });
    }else{
      setPermissionModules([])
    }
    return () => {
      dispatch(
        updateGlobalConfig((state) => {
          state.menuDrawer = {};
        })
      );
    };
    // eslint-disable-next-line
  }, [state?.allPermissions]);

  const validation = Yup.object({
    name: Yup.string().required("Enter Name").max(80, "Enter valid name"),
    level: Yup.string().required("Enter Level").max(80, "Enter valid level "),
    // modules:Yup.array().required("Select Atleast one Modules")
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      level: "",
      modules: "",
      status: status,
    },
    validationSchema: validation,
    onSubmit: (values) => {
      if (!update) {
        let data = {
          name: values?.name,
          level: Number(values?.level),
          modules: permissionModules,
        };
        AddRole(data)
          .then((response) => {
            if (response.status === 200) {
              dispatch(role.util.invalidateTags(["role"]));
              navigate("/configure/role");
              toast.success("Role created successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (response?.success === false) {
              if (response?.message?.name) {
                formik?.setFieldError("name", response?.message?.name);
              } else if (response?.message?.modules) {
                formik?.setFieldError("modules", response?.message?.modules);
              }
            } else if (response?.status_code === 422) {
              if (response?.errors?.name) {
                formik?.setFieldError("name", "Name is already taken");
              } else if (response?.errors?.modules) {
                formik?.setFieldError(
                  "modules",
                  "The modules field is required."
                );
              } else {
                toast.error("Select some modules ");
              }
            } else {
              toast.error("Failed to create role");
            }
          })
          .catch(() => {
            toast.error("Failed to create role");
          });
      } else {
        let editData = {
          role_id: roleparam,
          name: values?.name,
          level: Number(values?.level),
          modules: permissionModules,
          status: status?1:0,
        };
        updateRole(editData).then((response) => {
          if (response.status === 200) {
            dispatch(
              updateConfig((state) => {
                state.allPermissionsStatus = "idle";
                state.allPermissions = [];
              })
            );
            dispatch(role?.util?.invalidateTags(["role"]));
            toast.success("Role updated successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            navigate("/configure/role");
            dispatch(
              getMenuPermissions({
                token: localStorage.getItem("USER_ACCESS_TOKEN"),
              })
            );
          } else {
            toast.error("Failed to update role");
          }
        });
      }
    },
  });
  const updateCheckboxState = (updatedModules) => {
    const object = {
      [roleparam || "rolename"]: {
        _id: roleparam,
        name: formik.values.name,
        level: 2,
        modules: updatedModules,
        status: 1,
      },
    };
    dispatch(
      updateConfig((state) => {
        state.rolePermission = object;
      })
    );
  };

  useEffect(() => {
    if (state.rolePermissionStatus === "fulfilled") {
      setPermissionModules(
        state.rolePermission?.[roleparam || "rolename"]?.modules
      );
    }
  }, [state, roleparam]);
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const handleStatusChange = () => {
    setStatus(!status);
  };
  const handleMenu = (e, moduleId) => {
    setPermissionModules((prev) => {
      const updatedModules = [...prev];
      const moduleIndex = updatedModules.findIndex((mod) => mod.module_id === moduleId);
      if (e.target.checked) {
        const moduleListIndex = state?.allPermissions?.findIndex((mod) => mod._id === moduleId);
        const permissionsArray = state?.allPermissions[moduleListIndex]?.permissions?.map((item) => {
          return {
            id: item.id,
            ...(Array.isArray(item.sub_menu_permissions) && {
              sub_menu_permissions: item.sub_menu_permissions.map((subItem) => subItem.id),
            }),
          };
        });
        if (moduleIndex === -1) {
          updatedModules.push({ module_id: moduleId, permissions: permissionsArray });
        }
      } else if (moduleIndex !== -1) {
        updatedModules.splice(moduleIndex, 1);
      }
      updateCheckboxState(updatedModules);
      return updatedModules;
    });
  };
  const handleSubMenu = (item, moduleId) => {
    const moduleIndex = state?.allPermissions?.findIndex((mod) => mod._id === moduleId);
    const permissionIndex = state?.allPermissions[moduleIndex]?.permissions.findIndex((perm) => perm.id === item.id);
    const subMenuPermissions = state?.allPermissions[moduleIndex]?.permissions[permissionIndex]?.sub_menu_permissions?.map((sub) => sub.id) || [];
    setPermissionModules((prev) => {
      const updatedModules = [...prev];
      const moduleIndexInPrev = updatedModules.findIndex((mod) => mod.module_id === moduleId);
      if (item.e.target.checked) {
        const newPermission = { id: item.id, ...(subMenuPermissions.length && { sub_menu_permissions: subMenuPermissions }) };
        if (moduleIndexInPrev !== -1) {
          const updatedModule = { 
            ...updatedModules[moduleIndexInPrev],
            permissions: [...updatedModules[moduleIndexInPrev].permissions, newPermission] 
          };
          updatedModules[moduleIndexInPrev] = updatedModule;
        } else {
          updatedModules.push({ module_id: moduleId, permissions: [newPermission] });
        }
      } else if (moduleIndexInPrev !== -1) {
        const filteredPermissions = updatedModules[moduleIndexInPrev].permissions.filter((perm) => perm.id !== item.id);       
        if (filteredPermissions.length > 0) {
          // Create a copy of the module and update permissions
          updatedModules[moduleIndexInPrev] = { 
            ...updatedModules[moduleIndexInPrev], 
            permissions: filteredPermissions 
          };
        } else {
          // If no permissions remain, remove the module
          updatedModules.splice(moduleIndexInPrev, 1);
        }
      }
      updateCheckboxState(updatedModules);
      return updatedModules;
    });
  };
  const handleSubmenuPermission = (item, moduleId) => {
    setPermissionModules((prev) => {
      const updatedModules = prev.map((module) => ({
        ...module,
        permissions: module.permissions.map((perm) => {
          const updatedSubMenuPermissions = Array.isArray(perm.sub_menu_permissions)
            ? [...perm.sub_menu_permissions]
            : [];
          return {
            ...perm,
            ...(updatedSubMenuPermissions.length > 0 && { sub_menu_permissions: updatedSubMenuPermissions }),
          };
        }),
      })); 
      const moduleIndex = updatedModules.findIndex((mod) => mod.module_id === moduleId);
 
      if (item.e.target.checked) {
        // Handle adding submenu permissions
        if (moduleIndex !== -1) {
          const subIndex = updatedModules[moduleIndex]?.permissions?.findIndex(
            (perm) => perm?.id === item?.subId
          );
          if (subIndex !== -1) {
            const updatedSubMenuPermissions = [
              ...updatedModules[moduleIndex].permissions[subIndex].sub_menu_permissions,
              item.id,
            ];
            updatedModules[moduleIndex].permissions[subIndex] = {
              ...updatedModules[moduleIndex].permissions[subIndex],
              sub_menu_permissions: updatedSubMenuPermissions,
            };
          } else {
            updatedModules[moduleIndex] = {
              ...updatedModules[moduleIndex],
              permissions: [
                ...updatedModules[moduleIndex].permissions,
                { id: item?.subId, sub_menu_permissions: [item.id] },
              ],
            };
          }
        } else {
          updatedModules.push({
            module_id: moduleId,
            permissions: [{ id: item?.subId, sub_menu_permissions: [item.id] }],
          });
        }
      } else if (moduleIndex !== -1) {
        // Handle unchecking and removing submenu permissions
        const subIndex = updatedModules[moduleIndex].permissions.findIndex((perm) => perm.id === item?.subId);
        if (subIndex !== -1) {
          const updatedSubMenuPermissions = updatedModules[moduleIndex].permissions[subIndex].sub_menu_permissions.filter(
            (subPerm) => subPerm !== item.id
          );
  
          if (updatedSubMenuPermissions.length > 0) {
            updatedModules[moduleIndex].permissions[subIndex] = {
              ...updatedModules[moduleIndex].permissions[subIndex],
              sub_menu_permissions: updatedSubMenuPermissions,
            };
          } else {
            updatedModules[moduleIndex].permissions = updatedModules[moduleIndex].permissions.filter(
              (perm) => perm.id !== item?.subId
            );
            // Remove the entire module if no permissions left
            if (updatedModules[moduleIndex].permissions.length === 0) {
              updatedModules.splice(moduleIndex, 1);
            }
          }
        }
      }
      updateCheckboxState(updatedModules);
      return updatedModules;
    });
  };
  
  
  return {
    update,
    status,
    state,
    roleparam,
    formik,
    getFieldError,
    handleStatusChange,
    handleMenu,
    handleSubMenu,
    handleSubmenuPermission,
  };
};

export default useManageRole;
